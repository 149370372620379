<template>
  <div class="study" v-loading="pageLoading">
    <div class="studyHeader">
      <!-- <img
        :src="
          user.userface ||
          'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/default_avatar.png'
        "
        class="avatar"
        alt=""
      />
      <div class="user">
        <p class="name">{{ user.name }}</p>
      </div> -->
      <div class="time">
        <p>
          <span>{{ studyInfo.total_time }}</span
          >分钟
        </p>
        累计学习(分钟)
      </div>
      <div class="data">
        <div class="item">
          <p class="value">{{ studyInfo.today_time }}<span>分钟</span></p>
          <p class="label">今日学习 (分钟)</p>
        </div>
        <div class="item">
          <p class="value">{{ studyInfo.day_number }}<span>天</span></p>
          <p class="label">连续学习 (天)</p>
        </div>
        <div class="item">
          <p class="value">{{ studyInfo.lesson_number }}<span>个</span></p>
          <p class="label">完成课程 (个)</p>
        </div>
      </div>
    </div>
    <div class="text">学习记录</div>
    <div class="record">
      
      <div class="tab">
        <p
          class="title"
          :class="{ studyActive: activeIndex == item.category_id }"
          @click="changeList(item.category_id)"
          v-for="item in cateList"
          :key="item.category_id"
        >
          {{ item.category_name }}
        </p>
      </div>
      <div class="list" v-if="plat == '205'">
        <van-list
          v-loading="listLoading"
          v-if="list.length"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div
            class="card"
            v-for="(item, index) of list"
            :key="index"
            @click="jumpDetail(item)"
          >
            <div class="content">
              <van-image class="cover" fit="cover" :src="item.cover">
                <template v-slot:error>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
                  ></van-image>
                </template>
                <template v-slot:loading>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
                  ></van-image>
                </template>
              </van-image>
              <div class="detail">
                <p class="title">
                  {{ item.name }}
                </p>
                
                <div class="handle">
                  <p>{{ item.lesson_name }}</p>

                  <div class="btn">去学习</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty style="margin: 0 auto" v-if="!list.length" description="暂无数据" />
      </div>
      <div class="list" v-else>
        <van-list
          v-loading="listLoading"
          v-if="tuoyuList.length"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div
            class="card"
            v-for="(item, index) of tuoyuList"
            :key="index"
            @click="jumpDetail(item)"
          >
            <div class="content">
              <van-image class="cover" fit="cover" :src="item.cover">
                <template v-slot:error>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    :src="$tuoyuPlaceImg"
                  ></van-image>
                </template>
                <template v-slot:loading>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    :src="$tuoyuPlaceImg"
                  ></van-image>
                </template>
              </van-image>
              <div class="detail">
                <p class="title">
                  {{ item.name }}
                </p>
                <div  class="handle"><p>{{ item.lesson_name }}</p></div>
                <div class="btn">去学习</div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty
          style="margin: 0 auto"
          v-if="!tuoyuList.length"
          description="暂无数据"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      tuoyuList: [],
      cateList: [
        {
          name: "课程学习记录",
          id: 1,
        },
        {
          name: "培训学习记录",
          id: 2,
        },
      ],
      user: {},
      activeIndex: 1,
      classify: 0,
      loading: false,
      pageLoading: true,
      finished: true,
      more: false,
      listLoading: false,
    };
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    window.addEventListener("scroll", this.onLoad);
    this.pageLoading = false;
    if (this.plat == "119" || this.plat == "171") {
      this.$request.tuoyuCategory({ flat_id: this.plat }).then((res) => {
        this.cateList = res.data;
        this.changeList(this.cateList[0].category_id);
      });
    } else {
      this.$store.dispatch("getStudy");
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    list(e) {
      return this.activeIndex == 1 ? this.studyInfo.list : this.studyInfo.zi_list;
    },
  },
  methods: {
    changeList(id) {
      this.activeIndex = id;
      this.$request
        .tuoyuStudy({
          flat_id: this.plat,
          category_id: id,
        })
        .then((res) => {
          this.tuoyuList = res.data.list;
        });
    },
    jumpDetail(item) {
      if (this.plat == 119 || this.plat == 171) {
        this.$router.push(`./detail2?id=${item.goods_id}`);
      } else {
        this.$router.push(`./detail?id=${item.goods_id}&type=video`);
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.study {
  min-height: calc(100vh - 230px);
  font-family: "SourceHanSans";
  .studyHeader {
    width: 100%;
    height: 226px;
    background: #ffffff
      url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/centerBg.jpg")
      bottom center no-repeat;
    border-radius: 10px;
    margin-top: 116px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
    box-shadow: 0px 0px 16px rgba($color: #d6dadc, $alpha: 0.42)
  }
  .text{
    
    font-size: 24px;
    color: #242424;
    font-weight: bold;
    border-bottom: 1px dashed #f0f0f0;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
  }
  .avatar {
    position: absolute;
    top: -76px;
    left: 50%;
    transform: translateX(-50%);
    width: 152px;
    height: 15rpx;
    border-radius: 50%;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
  }

  .name {
    font-size: 38px;
    font-weight: 600;
    color: #333;
  }

  .time {
    font-size: 16px;
    color: #93959e;
    margin-top: 15px;
    text-align: center;
  }

  .time p {
    color: #006bb3;
    padding-bottom: 10px;
    span {
      font-family: 'SourceHanSansBold';
      font-size: 30px;
    }
  }

  .data {
    width: 90%;
    margin: 30px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    box-sizing: border-box;
    text-align: center;
  }

  .data .value {
    color: #006bb3;
    font-size: 26px;
    font-family: 'SourceHanSansBold';
    span {
      font-size: 14px;
      font-weight: normal;
      font-family: 'SourceHanSans';
    }
  }

  .data .label {
    color: #93959e;
    font-size: 16px;
    margin-top: 10px;
  }

  .record {
    // height: calc(100vh - 708px);
    // background: #fff;
    .tab {
      padding-top: 30px;
      padding-bottom: 20px;
      margin-bottom: 13px;
      .title {
        color: #7f7f7f;
        font-size: 20px;
        padding: 0 30px;
        height: 70px;
        display: flex;
        align-items: center;
        display: inline;
        cursor: pointer;
      }
      .studyActive {
        font-weight: 600;
        position: relative;
        color: #006bb3;
        font-weight: bold;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   bottom: -15px;
        //   display: block;
        //   width: 63px;
        //   height: 5px;
        //   background: #0080d6;
        // }
      }
    }
  }
}
.list {
  min-height: 200px;
}
.card {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  width: 380px;
  height: 154px;
  box-sizing: border-box;
  margin-right: 20px;
  &:nth-child(3n + 3) {
    margin-right: 0;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    width: 100%;

    .cover {
      margin-right: 20px;
      width: 134px;
      height: 134px;
      border-radius: 10px;
      overflow: hidden;
    }

    .detail {
      display: flex;
      
      flex-direction: column;
      flex: 1;
      .title{
        font-size: 16px;
        font-weight: bold;
      }
    
      .text {
        font-size: 14px;
        color: #666;
        display: flex;
        width: 100%;
        span {
          flex: 1;
        }
      }

      .more {
        font-size: 14px;
        color: #175199;
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
      }
    }

    .handle {
      width: 100%;
      flex: 1;
      font-size: 14px;
      color: #7f7f7f;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        height: 24px;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 1;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .btn {
      cursor: pointer;
      height: 34px;
      border-radius: 4px;
      line-height: 34px;
      font-weight: 500;
      color: #006bb3;
      background: #fff;
      font-size: 14px;
      text-align: center;
      &:hover {
        color: #fff;
        background: #006bb3;
      }
    }
  }
}
</style>

<style lang="scss">
body{
  background: #fff;
}
</style>
